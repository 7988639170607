/** @jsx jsx */
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import { Box, jsx } from "theme-ui";
import React, { useCallback } from "react";
import LayoutHome from "../layout/LayoutHome";
import { eventCallbackTriggers } from "../templates/wppage";

const pageContent =
  '<gatsby_donation amount="0" btntext="Proceed to Registration" donationid="2"' +
  'projectid="" btnbg="#4d8f15" colortext="black" colorprimary="forestgreen"' +
  'colorsecondary="darkgreen" colorbackground="white" colormuted="#f6f6f6"' +
  'colorhighlight="#efeffe" titletext="Heartfulness SMSF India – General Fund offline" />';
const PageContent = React.memo(PageContentNonMemoized);

const DonationSMSFIndiaGFOffline = () => {
  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("smsf-india-general-fund-offline", event),
    []
  );

  return (
    <LayoutHome
      seoTitle="Donation for SMSF India – General Fund offline – Donations"
      contentHeaderStyle="contentHeaderText"
      contentHeader={<h3>Heartfulness Donation Registration</h3>}
    >
      <Box>
        <h3 sx={{ mb: 3, mt: 3 }}>
          Donation for SMSF India – General Fund offline
        </h3>
        <p>
          <b>Welcome!</b> Thank you for choosing to donate to Sahaj Marg
          Spirituality Foundation, India. Sahaj Marg Spirituality Foundation
          supports all the events and programs undertaken by{" "}
          <b>heartfulness.</b> On completion of the donation registration, a
          confirmation email with instructions will be sent to you.
        </p>
        <p>
          <b>
            Donation to General Fund would be used for the objectives of the
            Organization and Heartfulness.
          </b>
        </p>
        <br />
        <br />
        <PageContent eventCallback={eventCallback} pageContent={pageContent} />
      </Box>
    </LayoutHome>
  );
};

export default DonationSMSFIndiaGFOffline;
